import React from "react"
import Layout from "../components/layout"
import { graphql } from 'gatsby'

const Contact = ({ data }) => (
    <Layout>
    <div className="fixed-top bg-light py-lg-3 text-center">
    <h1 className="d-none d-lg-inline-block mb-0 h2 lishan-xue">Lishan Xue</h1><br />
    <p className="d-none d-lg-inline-block fst-italic title">Pianist</p>
        <nav className="navbar navbar-expand-lg navbar-purple bg-light mx-3 mx-lg-none">
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <h1 className="d-lg-none pl-2 h2 fw-bold mobile-title">Lishan Xue<br /><span className="fst-italic h6 fw-normal">Pianist</span></h1>
            <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" href="/">Home | <span className="text-muted">主页</span></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/bio">About | <span className="text-muted">个人</span></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/news">News | <span className="text-muted">新闻</span></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/concerts">Concerts | <span className="text-muted">演出</span></a>
                    </li>
                    <li className="nav-item dropdown" id="media">
                        <a className="nav-link dropdown-toggle" href="/media" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Media | <span className="text-muted">媒体</span>
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a className="dropdown-item" href="/media/#videos">Videos</a>
                        <a className="dropdown-item" href="/media/#photos">Photos</a>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/repertoire">Repertoire | <span className="text-muted">常备曲目</span></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/teaching">Teaching | <span className="text-muted">教学</span></a>
                    </li>
                    <li className="nav-item active">
                        <a className="nav-link" href="/contact">Contact | <span className="text-muted">联系方式</span> <span className="sr-only">(current)</span></a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
    <div className="contact py-5">
    <div className="container my-5">
        <div className="row">
            <div className="col-lg-6 mx-auto">
            <div dangerouslySetInnerHTML={{
            __html: data.english.data.text.childMarkdownRemark.html,
            }} className="mb-3" />
            <div lang="zh-Hans" dangerouslySetInnerHTML={{
            __html: data.chinese.data.text.childMarkdownRemark.html,
            }} className="mb-5 chinese" />
                <form name="contact" netlify-honeypot="bot-field" method="POST" data-netlify="true">
                    <input type="hidden" name="form-name" value="contact" aria-label="contact form" />
                    <div className="form-group d-none">
                    <label>Don’t fill this out if you're human:<input name="bot-field" aria-label="bot field" /></label>
                    </div>
                    <div className="form-group pb-3">
                        <div id="nameId" className="sr-only">Name</div>
                        <input type="text" className="form-control" name="Name" placeholder="Name" aria-label="name" />
                    </div>
                    <div className="form-group pb-3">
                        <div id="emailId" className="sr-only">Email</div>
                        <input type="email" className="form-control" name="Email" placeholder="Email" aria-label="email" />
                    </div>
                    <div className="form-group pb-3">
                        <div id="messageId" className="sr-only">Message</div>
                        <textarea type="text" className="form-control" name="Message" rows="10" placeholder="Message" aria-label="message"></textarea>
                    </div>
                    <button type="submit" className="btn btn-light">Send</button>
                </form>

            </div>
        </div>
    </div>
    </div>
    </Layout>
)
export default Contact;

export const query = graphql`
{
    english:airtable(table: {eq: "Contact"}, data: {item: {eq: "intro"}, publish: {eq: true}}) {
        data {
            text {
                childMarkdownRemark {
                  html
                }
              }
        }
    }

    chinese:airtable(table: {eq: "Contact"}, data: {item: {eq: "intro-cn"}, publish: {eq: true}}) {
        data {
            text {
                childMarkdownRemark {
                  html
                }
              }
            }
        }
}  
`;